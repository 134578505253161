import React from "react";

// ui
import { SignInView } from "@/views/auth/signin/SignInView";
import { AuthLayout } from "@/layouts/AuthLayout";

export default function LoginPage() {
  return (
    <AuthLayout>
      <SignInView />
    </AuthLayout>
  );
}
